import { Paper } from "@mui/material";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import * as React from "react";
import { useState } from "react";
import request from "superagent";
import ReactSearchBox from "react-search-box";
import { useEffect } from "react";
import { baseApiUrl } from "../../services/setup";

const AdminHomePage = () => {
  return (
    <MDBContainer>
      <MDBRow>
        <Paper className="p-2">
          <StatusLabel />
        </Paper>
      </MDBRow>
    </MDBContainer>
  );
};

const StatusLabel = (props) => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [currentUsersDeposits, setCurrentDeposits] = useState(0);
  const [costOfSale, setCostOfSale] = useState(0);
  const [pendingTransactions, setPendingTransactions] = useState(0);
  const [amountOfPendingTransactions, setAmountOfPendingTransactions] =
    useState(0);
  const [profit, setProfits] = useState(0);
  const [accountDifference, setAccountDifference] = useState(0);
  const [sales, setSales] = useState(0);
  const [init, setInit] = useState(false);
  const [openingAcount, setOpeningAcount] = useState(0);
  const [openingUsersDeposit, setopeningUsersDeposit] = useState(0);

  useEffect(() => {
    if (!init) {
      handleDataFetch();
    }
  }, [init]);

  const handleDataFetch = async () => {
    try {
      const response = await request.get(baseApiUrl + "bussinessreport.php");

      const retVal = response.body;

      setWalletBalance(retVal.ourapiwallet);
      setCurrentDeposits(retVal.currentUsersDeposit);
      setCostOfSale(retVal.costofsale);
      setPendingTransactions(retVal.pendingTransactions);
      setAmountOfPendingTransactions(retVal.amountOfPendingTransactions);
      setProfits(retVal.profits);
      setAccountDifference(retVal.apibalance);
      setSales(retVal.sales);
      setOpeningAcount(retVal.openingAcount);
      setopeningUsersDeposit(retVal.openingUsersDeposit);

      setInit(true);
    } catch (err) {
      console.error("Error message:", err.response);
      console.error("ERROR", err);
    }
  };

  return (
    <div className=" m-2">
      <table className="wallet-table">
        <tr>
          <td>Wallet Balance</td>
          <td>{walletBalance}</td>
        </tr>

        <tr>
          <td>Opening Account</td>
          <td>{openingAcount}</td>
        </tr>

        <tr>
          <td>Opening customers deposits</td>
          <td>{openingUsersDeposit}</td>
        </tr>

        <tr>
          <td>Current customers deposits</td>
          <td>{currentUsersDeposits}</td>
        </tr>

        <tr>
          <td>Cost of sales made</td>
          <td>{costOfSale}</td>
        </tr>

        <tr>
          <td>Amount of sales made</td>
          <td>{sales}</td>
        </tr>

        <tr>
          <td>Account surplus</td>
          <td>{profit}</td>
        </tr>

        <tr>
          <td>Account deficit</td>
          <td>{accountDifference}</td>
        </tr>

        <tr>
          <td>Total pending transactions</td>
          <td>{pendingTransactions}</td>
        </tr>

        <tr>
          <td>Amount of pending transactions</td>
          <td>{amountOfPendingTransactions}</td>
        </tr>
      </table>
    </div>
  );
};

export default AdminHomePage;
