// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyA13D1XRTlGB4IiIONXl8dtRG8CG01v1HA",
  authDomain: "lemondata-e350d.firebaseapp.com",
  projectId: "lemondata-e350d",
  storageBucket: "lemondata-e350d.appspot.com",
  messagingSenderId: "394930405293",
  appId: "1:394930405293:web:ddc293aa96b69eeee26b24",
  measurementId: "G-LFTKTLP8R5",
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database(); // Get the database reference

export { firebase, database };
